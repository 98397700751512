import PropTypes from 'prop-types';
import Image from 'next/image';
import React from 'react';
import urlFor from '../../sanity/image/urlFor';

const SanityImage = ({ image, alt, ...props }) => {
  const urlForLoader = ({ width, quality }) =>
    urlFor(image).width(width).quality(quality).url();

  const imgExists = !!urlFor(image)?.options?.source;
  if (!imgExists) return null;

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      loader={urlForLoader}
      src={urlFor(image).url()}
      alt={alt}
      layout="fill"
    />
  );
};

SanityImage.propTypes = {
  alt: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default SanityImage;
